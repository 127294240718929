$(document).ready(function(){
    if($(".projets .list-projets").length > 0){
        $(".projets .list-projets").owlCarousel({
            loop: true,
            dots: false,
            nav:true,
            navText: ["",""],
            autoWidth: true,
            responsive:{
                768: {
                    items:4,
                },
                0: {
                    items: 1,
                    outoHeight: true
                }
            },
        });
    }

    if($(".projets .row.list").length > 0){
        var masonryOptions = {
            itemSelector: '.projet.active',
            percentPosition: true,
            transitionDuration: '0.3s',
        };
        
        $(window).on("load", function() {
            setTimeout(function(){
                $('.projets .row.list').masonry( masonryOptions ).masonry('layout');
            },500);
        });

        /*$(document).on("click", ".projets .filters .item", function(){
			var filterValue = $(this).attr('data-filter');
			$("[data-filter='"+filterValue+"']").addClass("active").siblings().removeClass("active");
			$(".row.list .projet").removeClass("active");
            $('.projets .row.list').masonry('destroy');
			$(".row.list .projet"+filterValue).addClass("active");
            $('.projets .row.list').masonry(masonryOptions).masonry('layout');
        });*/
        
        $(window).on("resize", function(){
            if($(window).innerWidth() <= 767) {
                $('.projets .row.list.masonry').masonry('destroy');
            } else {
                $('.projets .row.list').addClass("masonry").masonry(masonryOptions).masonry('layout');
            }
        });
    
        $(window).resize();
    }

    if($("#detail .slider-projets").length > 0){
        
        $("#detail .slider-projets .slider.owl-carousel").owlCarousel({
            items: 1,
            margin:0,
            nav: true,
            dots: false,
            loop:true,
            autoWidth:true,
            autoplay:true,
            autoplayTimeout:3000,
            autoplayHoverPause:true,
            nav:true,
            navText: ["",""]
        })
    }

    if($("#projets").length > 0){
        $(document).on("scroll", function(e){
            if($(this).scrollTop() >= ($(".section-realisations").offset().top - 50 )){
                $(".section-realisations .filters").addClass("fixed");
            } else {
                $(".section-realisations .filters").removeClass("fixed");
            }
        });
    }
})