$(document).ready(function(){
    $(document).on("scroll", function(e){
        if($(this).scrollTop() >= 500 ){
            $("#nav").addClass("fixed");
            setTimeout(function(){
                $("#nav").addClass("on-fixed");
            },250);
            
        } else {
            $("#nav").removeClass("fixed on-fixed");
        }
    });

    if($(document).scrollTop() >= 500 ){
        $("#nav").addClass("fixed");
        setTimeout(function(){
            $("#nav").addClass("on-fixed");
        },250);
        
    } else {
        $("#nav").removeClass("fixed on-fixed");
    }

    $(document).on("click", "#nav .btn-menu", function(){
        if($("#nav .inner-content").hasClass("open")){
            $("#nav .inner-content").removeClass("open");
            $("#nav .logo").removeClass("open");
            $("html").removeClass("hidden");
        } else {
            $("#nav .inner-content").addClass("open");
            $("#nav .logo").addClass("open");
            $("html").addClass("hidden");
        }
    });

    $(document).on("click", function(e){
        if($(e.target).parents("#nav").length == 0) {
            $("#nav .inner-content").removeClass("open");
            $("#nav .logo").removeClass("open");
            $("html").removeClass("hidden");
        }
    })
});