$(document).ready(function(){
    
    if($(".header .slider").length > 0){

        var isPaused = false;
        setInterval(function(){
            if(!isPaused) {
                var $dataSlider = $(".header .slider .item.active").data("slider");
                if($("[data-slider="+$dataSlider+"]").next().length == 0) {
                    $(".header .slider .item").first().addClass("active").siblings().removeClass("active");
                    $dataSlider = $(".header .slider .item.active").data("slider");
                    $(".img."+$dataSlider).addClass("active").siblings().removeClass("active");
                    $(".block."+$dataSlider).addClass("active").siblings().removeClass("active")
                } else {
                    $("[data-slider="+$dataSlider+"]").next().addClass("active").siblings().removeClass("active");
                    $(".img."+$dataSlider).next().addClass("active").siblings().removeClass("active");
                    $(".block."+$dataSlider).next().addClass("active").siblings().removeClass("active")
                }
            }
        }, 4000);
    
        $(document).on("click", ".header .slider .item", function(){
            isPaused = true;
            var $dataSlider = $(this).data("slider");
            $(this).addClass("active").siblings().removeClass("active");
            $(".img."+$dataSlider).addClass("active").siblings().removeClass("active");
            $(".block."+$dataSlider).addClass("active").siblings().removeClass("active");
            setTimeout(function(){ isPaused = false }, 4000);
        });
    
        $(document).on("click", ".header .slider-next", function(){
            isPaused = true;
            var $dataSlider = $(".header .slider .item.active").data("slider");
            if($("[data-slider="+$dataSlider+"]").next().length == 0){
                $(".header .slider .item").first().addClass("active").siblings().removeClass("active");
                $dataSlider = $(".header .slider .item.active").data("slider");
                $(".img."+$dataSlider).addClass("active").siblings().removeClass("active");
                $(".block."+$dataSlider).addClass("active").siblings().removeClass("active");
            } else {
                $("[data-slider="+$dataSlider+"]").next().addClass("active").siblings().removeClass("active");
                $(".img."+$dataSlider).addClass("active").siblings().removeClass("active");
                $(".img."+$dataSlider).next().addClass("active").siblings().removeClass("active");
                $(".block."+$dataSlider).next().addClass("active").siblings().removeClass("active");
            }
            setTimeout(function(){ isPaused = false }, 4000);
        });

        $(document).on("click", ".header .slider-prev", function(){
            isPaused = true;
            var $dataSlider = $(".header .slider .item.active").data("slider");
            if($("[data-slider="+$dataSlider+"]").prev().length == 0){
                $(".header .slider .item").last().addClass("active").siblings().removeClass("active");
                $dataSlider = $(".header .slider .item.active").data("slider");
                $(".img."+$dataSlider).addClass("active").siblings().removeClass("active");
                $(".block."+$dataSlider).addClass("active").siblings().removeClass("active");
            } else {
                $("[data-slider="+$dataSlider+"]").prev().addClass("active").siblings().removeClass("active");
                $(".img."+$dataSlider).addClass("active").siblings().removeClass("active");
                $(".img."+$dataSlider).prev().addClass("active").siblings().removeClass("active");
                $(".block."+$dataSlider).prev().addClass("active").siblings().removeClass("active");
            }
            setTimeout(function(){ isPaused = false }, 4000);
        });

        $(window).on("load", function(){
            $heightBlock = 0;
            $(".header .inner-container .block").each(function(index, elem){
                if($heightBlock <= $(elem).innerHeight()){
                    $heightBlock = $(elem).innerHeight();
                }
            });
            var $paddingTop = parseFloat($(".header .inner-container").css("padding-top").split("px")[0]),
                $paddingBottom = parseFloat($(".header .inner-container").css("padding-bottom").split("px")[0]);
            $(".header .inner-container").css("height",($heightBlock + $paddingTop + $paddingBottom)+"px"); 
        })


        $(window).on("resize", function(){
            $heightBlock = 0;
            $(".header .inner-container .block").each(function(index, elem){
                if($heightBlock <= $(elem).innerHeight()){
                    $heightBlock = $(elem).innerHeight();
                }
            });
            var $paddingTop = parseFloat($(".header .inner-container").css("padding-top").split("px")[0]),
                $paddingBottom = parseFloat($(".header .inner-container").css("padding-bottom").split("px")[0]);
            $(".header .inner-container").css("height",($heightBlock + $paddingTop + $paddingBottom)+"px"); 
        });

        $(window).resize();
    }
});