//=require vendors/jquery.min.js
//=require vendors/owl.carousel.min.js
//=require vendors/paroller.js
//=require vendors/masonry.js

//=require modules/menu.js
//=require modules/slider-header.js
//=require modules/presontation.js
//=require modules/footer.js

//=require section/projets.js

//=require section/projets.js

$(document).ready(function(){
    if($(".contact-filter").length > 0) {
        $(document).on("click", ".contact-filter a", function(){
            $("html,body").animate({
                scrollTop: $(".section."+$(this).data("scroll")).offset().top
            },500)
        });
    }

    if(!$("body").hasClass("mobile")) {
        $(".prestation").each(function (index, element) { 
            $(element).find(".container").paroller({
                factor: 0.05,
                factorXs: 0,
                type: "foreground",
                direction: "vertical",
            });
        });

        $(".projets").each(function (index, element) { 
            $(element).find(".projets-parallaxe").paroller({
                factor: 0.05,
                type: "foreground",
                direction: "vertical",
            });
        });

        $(".equipes").each(function (index, element) { 
            $(element).find(".container").paroller({
                factor: 0.05,
                type: "foreground",
                direction: "vertical",
            });
        });

        $(".contact").each(function (index, element) { 
            $(element).find(".container").paroller({
                factor: 0.05,
                type: "foreground",
                direction: "vertical",
            });
        });

        $(".detail-section").each(function (index, element) { 
            $(element).find(".container").paroller({
                factor: 0.05,
                type: "foreground",
                direction: "vertical",
            });
        });
    } else {
        $(document).on("click", ".picto", function(e){
            if(!$(this).hasClass("is-hover")) {
                e.preventDefault();
                $(this).addClass("is-hover")
            }
        });
    }

    if($("#home").length == 0){
        $(window).on("resize", function(){
            if($(window).innerWidth() <= 768){

                if($(".header .inner-container > span").length > 0){
                    $(".header .bg, .header .bg-color").css({
                        top: ($(".header h1").innerHeight() + ($(".header .inner-container > span").innerHeight() +15) + 45) +"px"
                    });
                } else {
                    $(".header .bg, .header .bg-color").css({
                        top: ($(".header h1").innerHeight() + 45) +"px"
                    });
                }
                
            } else {
                $(".header .bg, .header .bg-color").css({
                    top: "auto" 
                });
            }
        });

        $(window).resize();
    }

   
});