$(document).ready(function(){
    $(document).on("click", "#footer .items", function(e){
        if(!$(e.target).parents().hasClass("content-footer")) {
            if($(this).hasClass("active")){
                $(this).removeClass("active");
            } else {
                $(this).addClass("active").siblings().removeClass("active");
            }
        }
    });

    $(document).on("click", "#footer .items .drop.open > li", function(){
        $(this).addClass("active").siblings().removeClass("active");
        $("#footer .items .block ."+$(this).data("filter")).addClass("active").siblings().removeClass("active");
        $(this).parent().removeClass("open");
        return false;
    });

    $(document).on("click", "#footer .items .drop", function(){
        $(this).addClass("open");
    });
});