$(document).ready(function(){
    $(document).on("click", ".prestation .services .picto", function(e){
        if(!$(e.target).parents().hasClass("content-service") && !$(e.target).hasClass("content-service")) {
            if($(this).hasClass("active")){
                $(this).removeClass("active");
            } else {
                $(this).addClass("active").parent().siblings().find(".picto").removeClass("active");
            }
            return false;
        }
    });
});